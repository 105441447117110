nav {
    width: 100%;
    height: 65px;
    padding-top: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;

    top: 0px;
    z-index: 4;

    // border-bottom: 1px solid #9e9d9d4a;
    // background: linear-gradient(180deg, #0000007d 100%, #ffffff1a 200%);
    // box-shadow: 0px 4px 24px 0px #23105e40;
    // backdrop-filter: blur(22.5px);
    
	// transition: all 0.2s ease-in-out;

    @media (max-width: 562px) {
        height: 50px;
    }

    .nav-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .logo {
        padding-top: 6px;
        img {
            height: 36px;
            @media (max-width: 768px) {
                height: 32px;
            }
            @media (max-width: 562px) {
                height: 26px;
            }
        }
    }
    .nav-links {
        ul {
            display: flex;
            gap: 24px;
            font-size: 15px;
            font-weight: 700;
            li {
                padding-right: 25px;
            }
        }
        @media (max-width: 768px) {
            display: none;
        }
    }
    .Get-in-touch .btn {
        border-radius: 8px;
        background: #fff;
        color: black;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;

        @media (max-width: 768px) {
            display: none;
        }
    }

    .collapse-menu {
        display: none;
        width: 60px;

        @media (max-width: $breakpoint-md) {
            display: block;
        }

        .nav-toggler {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 10px;
            height: 40px;
            background: none;
            border: none;
            position: relative;
            cursor: pointer;
            z-index: 999;

            @media (max-width: 768px) {
                scale: 0.8;
            }
            @media (max-width: 562px) {
                scale: 0.6;
            }
            span {
                position: absolute;
                height: 3px;
                width: 80%;
                background: $teal;
                border-radius: 9px;
                transition: 0.25s ease-in-out;
            }
            span:nth-child(1) {
                top: 8px;
            }
            span:nth-child(2),
            span:nth-child(3) {
                width: 48px;
                top: 20px;
            }
            span:nth-child(4) {
                top: 32px;
            }
        }

        .nav-toggler.open {
            span:nth-child(1) {
                top: 18px;
                width: 0%;
                left: 50%;
            }
            span:nth-child(2) {
                -webkit-transform: rotate(45deg);
                transform: rotate(45deg);
            }
            span:nth-child(3) {
                -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
            }
            span:nth-child(4) {
                top: 18px;
                width: 0%;
                left: 50%;
            }
        }

        .open-menu-container {
            overflow: none;
            &.slide-down {
                visibility: visible;
                animation: slide-down 0.3s ease-out forwards;
            }

            &.slide-up {
                animation: slide-up 0.3s ease-out forwards;
            }
            @media (max-width: 768px) {
                position: fixed;
                width: 100vw;
                height: 100vh;
                top: 0;
                left: 0;
            }

            #menu {
                position: absolute;
                left: 0;
                top: 0;
                align-items: center;
                display: flex;
                justify-content: center;
                height: 100vh;
                width: 100vw;
                background: $navy;
                border-bottom: 2px solid $teal;
                animation-name: swing-in-top-fwd, swing-out-top-bck;
                animation-duration: 2s, 1s;
                animation-delay: 0s, 1s;
                animation-fill-mode: forwards, forwards;
                animation-timing-function: ease-in-out, ease-in-out;
                animation-iteration-count: 1, 1;
                overflow: hidden;
                z-index: 111;
            }

            #menu-items {
                margin-left: clamp(4rem, 20vw, 48rem);
                font-family: "Ibarra Real Nova", serif;
                position: relative;
                z-index: 2;
                text-align: center;
            }

            #menu[data-active-index="0"] > #menu-background-pattern {
                background-position: 0% -25%;
            }

            #menu[data-active-index="1"] > #menu-background-pattern {
                background-position: 0% -50%;
            }

            #menu[data-active-index="2"] > #menu-background-pattern {
                background-position: 0% -75%;
            }

            #menu[data-active-index="3"] > #menu-background-pattern {
                background-position: 0% -100%;
            }

            #menu[data-active-index="0"] > #menu-background-image {
                background-position: center 45%;
            }

            #menu[data-active-index="1"] > #menu-background-image {
                background-position: center 50%;
            }

            #menu[data-active-index="2"] > #menu-background-image {
                background-position: center 55%;
            }

            #menu[data-active-index="3"] > #menu-background-image {
                background-position: center 60%;
            }

            #menu-background-pattern {
                background-image: radial-gradient(#75ffcc55 9%, transparent 9%);
                background-position: 0% 0%;
                background-size: 12vmin 12vmin;
                height: 100vh;
                left: 0px;
                position: absolute;
                top: 0px;
                transition:
                    opacity 800ms ease,
                    background-size 800ms ease,
                    background-position 800ms ease;
                width: 100vw;
                z-index: 1;
            }

            #menu-background-image {
                background-image: url(../../assets/menubg.png);
                background-position: center 40%;
                background-size: 110vmax;
                height: 100%;
                left: 0px;
                opacity: 0.15;
                position: absolute;
                top: 0px;
                transition:
                    opacity 800ms ease,
                    background-size 800ms ease,
                    background-position 800ms ease;
                width: 100%;
                z-index: 0;
            }

            #menu-items:hover ~ #menu-background-pattern {
                background-size: 11vmin 11vmin;
                opacity: 0.5;
            }

            #menu-items:hover ~ #menu-background-image {
                background-size: 100vmax;
                opacity: 0.1;
            }

            #menu-items:hover > .menu-item {
                opacity: 0.3;
            }

            #menu-items:hover > .menu-item:hover {
                opacity: 1;
            }

            .menu-item {
                color: $teal;
                cursor: pointer;
                display: block;
                font-size: clamp(2.5rem, 8vw, 8rem);
                padding: clamp(0.25rem, 0.5vw, 1rem) 0rem;
                text-decoration: none;
                transition: opacity 400ms ease;

                a {
                    color: $teal;
                    @media (max-width: 576px) {
                        font-size: 18px;
                    }
                }
            }
        }
    }
}












