.faqs {
    width: 100%;
    height: auto;
    min-height: 100vh;
    padding-top: 30px;

    position: relative;
	display: flex;
	align-self: center;
	justify-content: center;

    .container {
        display: flex;
        align-items: center;
        flex-direction: column;
        .faqs-header {
            text-align: center;
            width: 80%;
            @media (max-width: 768px) {
                width: 98%;
            }
            h2 {
                font-size: 28px;
                margin-bottom: 15px;
                span {
                    color: $teal;
                    font-size: 32px;
                }
            }
            p {
                color: $grey;
                margin-bottom: 32px;
                line-height: 24px;
                padding: 5px 8%;
                @media (max-width: 768px) {
                    padding: 5px 8px;
                    font-size: medium;
                }
            }
        }

        .dollar-img {
            height: 100%;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: absolute;
            img {
                width: 12%;
            }
        }
        .faqs-accordian-container {
            width: 70%;

            @media (max-width: 768px) {
                width: 98%;
            }

            .accordion {
                height: auto;
                margin-bottom: 20px;
                align-items: center;
                padding: 20px;
                background: linear-gradient(90deg, #181818 0%, #040018 100%);
                border-radius: 6px;
                
                &:nth-child(2),
                &:nth-child(4) {
                    transform: translateX(0%);
                }
                &:nth-child(1),
                &:nth-child(3),
                &:nth-child(5) {
                    transform: translateX(10%);
                }

                .accordion-header {
                    width: 100%;
                    display: flex;
                    font-size: 32px;
                    align-items: center;
                    justify-content: space-between;
                    cursor: pointer;

                    @media (max-width: 768px) {
                        font-size: 22px;
                    }

                    h6 {
                        margin: 0;
                    }

                    .accordion-icon {
                        display: flex;
                        align-items: center;
                    }
                }

                .accordion-content {
                    overflow: hidden;
                    p {
                        padding-top: 10px;
                        color: $grey;
                        line-height: 24px;
                    }
                }
            }
        }
    }
}
