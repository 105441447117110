.about-page {
	width: 100%;
	height: auto;
	position: relative;
	display: flex;
	align-self: center;
	justify-content: center;

	.container {
		padding-top: 40px;

		.spoted-rectangle {
			position: absolute;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 100%;
			left: 0;
            z-index: -1;

			.rectangle {
				position: absolute;
				display: grid;
				grid-template-columns: repeat(60, 20px);
				grid-gap: 5px;
				width: 100%;
				height: 100%;
				margin: auto;
				max-width: 1500px;
				.spot {
					width: 2px;
					height: 2px;
					background-color: #34c8e8;
					cursor: pointer;
				}
			}
		}
		.about-me-txt {
			padding-top: 20px;
			font-size: x-large;
		}
		.about-disc-txt {
			padding-top: 5%;
			font-size: xx-large;
			line-height: 250%;
			span {
				color: #34c8e8;
			}

			@media (max-width: 992px) {
				font-size: larger;
			}
			@media (max-width: 768px) {
				font-size: medium;
			}
			@media (max-width: 562px) {
				font-size: medium;
			}
			@media (max-width: 392px) {
				font-size: x-small;
			}

			img {
				position: relative;
				z-index: 4;
				width: 100%;
				max-width: 1500px;
				border-radius: 20px;
				// background: #01567e;
				margin: 20px 0px;
			}
		}
	}
}
