.works {
	width: 100%;
	height: auto;
	min-height: 100vh;
	padding-top: 20px;
	padding-bottom: 20px;
	background-image: url("./../../assets/projectsbg.png");

	position: relative;
	display: flex;
	align-self: center;
	justify-content: center;

	.container {
		display: flex;
		align-items: center;
		flex-direction: column;
		position: relative;
		.work-header {
			text-align: center;
			width: 80%;

			@media (max-width: 768px) {
				width: 96%;
			}
			h2 {
				color: $teal;
				font-size: 42px;
				margin-bottom: 15px;

				@media (max-width: 768px) {
					font-size: 24px;
				}

				.magic {
					display: inline-block;
					position: relative;
					color: $teal;
					font-size: 54px;

					@media (max-width: 768px) {
						font-size: 32px;
					}
					.magic-star {
						--size: clamp(20px, 1.5vw, 30px);
						animation: scale 0.9s ease forwards;
						display: block;
						left: var(--star-left);
						position: absolute;
						top: var(--star-top);
						width: 15px;
						transform: translateY(50%);
						svg {
							animation: rotate 5s linear infinite;
							display: block;
							opacity: 1;
							transform: translateY(50px);
							path {
								fill: #8597fd;
							}
						}
					}
				}

				@keyframes background-pan {
					from {
						background-position: 0 center;
					}
					to {
						background-position: -500% center;
					}
				}
				@keyframes scale {
					from,
					to {
						transform: scale(0);
					}
					50% {
						transform: scale(1);
					}
				}
				@keyframes rotate {
					from {
						transform: rotate(0);
					}
					to {
						transform: rotate(180deg);
					}
				}
			}
			p {
				color: #b2ccfd;
				margin-bottom: 40px;
				font-size: 20px;
				padding: 0 10%;
				text-align: center;
				@media (max-width: 768px) {
					font-size: 14px;
					padding: 0 0;
				}
			}
		}

		.work-cards-container {
			display: flex;
			align-items: center;
			gap: 20px;
			justify-content: space-between;

			@media (max-width: 768px) {
				display: block;
			}

			.work-card {
				img {
					width: 100%;
					border-radius: 42px;
				}
				.work-card-text-cont {
					padding-top: 20px;
					margin-bottom: 6vh;
					h3 {
						font-size: 32px;
						color: $teal;
						margin-bottom: 10px;

						@media (max-width: 768px) {
							font-size: 24px;
						}
					}
					p {
						color: #b2ccfd;

						@media (max-width: 768px) {
							font-size: 14px;
						}
					}
				}
			}
		}

		.slide-work-container {
			width: 100%;
			height: auto;
			display: flex;
			align-items: center;
			gap: 10px;
			justify-content: space-between;
			margin-top: 40px;
			margin-bottom: 40px;

			@media (max-width: 768px) {
				display: block;
			}

			.slide-card {
				padding: 20px 15px 10px 15px;
				border-radius: 12px;
				transition: all 0.3s ease-in-out;
				box-shadow: 0 6px 26px 6px #000000b7;
				background: #001c2e34;
				border: 0.1px solid rgba(255, 255, 255, 0.185);

				* {
					cursor:
						url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="80" height="80"><circle cx="40" cy="40" r="33" fill="white"  /><text x="50%" y="50%" fill="black" font-size="13" font-family="sans-serif" text-anchor="middle">Swipe</text></svg>')
							40 40,
						auto;
				}
				&:hover {
					background: #01487734;
					transform: translateY(-5px);
					transition: "border 0.5s ease";
				}
				img {
					width: 100%;
					border-radius: 28px 60px 28px 80px;
				}
				.work-card-text-cont {
					padding-top: 20px;
					padding-bottom: 20px;
					h4 {
						color: $teal;
						font-size: 20px;
						margin-bottom: 10px;
					}
					p {
						color: #b2ccfd;
					}
				}
			}

			h3 {
				position: absolute;
				bottom: 25px;
				width: 100%;
				text-align: center;
			}
			.mySwiper {
				width: 100%;
				padding-top: 5px;
				padding-bottom: 45px;
				border-radius: 12px;
			}
		}
	}
}
