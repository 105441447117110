.brands {
	width: 100%;
	height: auto;
	display: flex;
	align-self: center;
	justify-content: center;
	padding-bottom: 40px;

	.container {
		video {
			width: 100%;
			border-radius: 20px;
		}
		canvas {
			// position: fixed;
			// left: 50%;
			// top: 50%;
			// transform: translate(-50%, -50%);
			// max-width: 100vw;
			// max-height: 100vh;
			
		}
		.brand-header {
            text-align: center;
			padding: 12px 0px;
            @media (max-width: 768px) {
                width: 98%;
            }
            h2 {
                font-size: 28px;
                margin-bottom: 15px;
                span {
                    color: $teal;
                    font-size: 32px;
                }
            }
            p {
                color: $grey;
                margin-bottom: 32px;
                line-height: 24px;
                padding: 5px 8%;
                @media (max-width: 768px) {
                    padding: 5px 8px;
                    font-size: medium;
                }
            }
        }
	}
}
