@import "_variables";
@import "./typology";
@import "components/footer";
@import "components/navbar";
@import "pages/landing";
@import "pages/about";
@import "pages/skill";
@import "pages/projects";
@import "pages/FAQs";
@import "pages/brands";

// @import "./components/entrance";
// @import "components/skill";
// @import "components/process";
// @import "components/works";
// @import "components/web-success";
// @import "components/FAQs";

*,
::before,
::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
    scroll-behavior: smooth;
    color: #ffffff;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

/*---Side Scroll Bar ---*/
::-webkit-scrollbar {
    width: 0.7vmin;
}
::-webkit-scrollbar-track {
    background: #010101;
}
::-webkit-scrollbar-thumb {
    background: linear-gradient(135deg, #000103ad 0%, #34c8e8 100%);
    border-radius: 12px;
}
::-webkit-scrollbar-thumb:hover {
    background: #020610;
}

body {
    width: 100%;
    height: auto;
    font-family: "Inter", sans-serif;

    // background: #18042b;
    // background: #042425;
    // background: #233196;

    background: #040615;
    // background: $bgColor;
}

.App {
    @extend .place-items-center;
    width: 100%;
    height: auto;
}

// entrance
$bgColor: #020438;
$boxColor: #020438;
$waterFColor: #4d6de3;
$waterBColor: #c7eeff;

// body {
//   background:$bgColor;
//   font: 14px/1 'Open Sans', helvetica, sans-serif;
//   -webkit-font-smoothing: antialiased;
// }

.box {
    height: 280px;
    width: 280px;
    // position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: $boxColor;
    
    border-radius: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: scale-out-center 5s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;

    @keyframes scale-out-center {
        0% {
            transform: scale(1);
            opacity: 1;
        }
        100% {
            transform: scale(0);
            opacity: 1;
        }
    }
    .percent {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 3;
        width: 100%;
        height: 100%;
        display: flex;
        display: -webkit-flex;
        align-items: center;
        justify-content: center;
        color: #fff;
        font-size: 64px;
    }
    .water {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 2;
        width: 100%;
        height: 100%;
        transform: translate(0, 100%);
        background: linear-gradient(270deg, $teal 0%, #2A00FF 77.32%);

        transition: all 0.3s;
        &_wave {
            width: 200%;
            position: absolute;
            bottom: 100%;
            &_back {
                right: 0;
                fill: $waterBColor;
                animation: wave-back 1.4s infinite linear;
            }
            &_front {
                left: 0;
                fill: linear-gradient(180deg, #224EF0 0%, #469AF2 100%);
                margin-bottom: -1px;
                animation: wave-front 0.7s infinite linear;
            }
        }
    }
}
@keyframes wave-front {
    100% {
        transform: translate(-50%, 0);
    }
}

@keyframes wave-back {
    100% {
        transform: translate(50%, 0);
    }
}
