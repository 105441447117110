.landing {
    width: 100%;
    padding-top: 65px;
    // padding-bottom: 70vh;
    padding-bottom: 700px;
    min-height: 100vh;

    @media (max-width: 768px) {
        padding-top: 50px;
        padding-bottom: 56vh;
    }
    @media (max-width: 562px) {
        padding-top: 30px;
        padding-bottom: 3vh;
    }
    @media (max-width: 392px) {
        padding-bottom: 30vh;
        min-height: auto;
    }

    .container {
        position: relative;

        .bg-video {
            position: absolute;
            width: 100%;
            left: 0;
            top: 0;
            height: 40vh;
            z-index: -1;

            @media (max-width: 768px) {
                width: 100vw;
                overflow-x: hidden;
                overflow-y: scroll;
            }
        }
        .ellipse-shadow {
            position: absolute;
            width: 300%;
            left: -100%;
            top: 0;
            height: 150vh;
            flex-shrink: 0;
            border-radius: 50%;
            z-index: -1;
            transform: translateY(-120vh);
            background: radial-gradient(
                25.02% 25.02% at 50.01% 74.98%,
                rgba(189, 253, 39, 0) 0%,
                rgba(38, 209, 246, 0.54) 0.01%,
                rgba(51, 129, 246, 0.28) 38.01%,
                rgba(23, 42, 110, 0) 100%
            );
        }
        .center-ellipse-line {
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 1;
            .ellipse-line {
                $circleWidth: 35%;
                width: $circleWidth;
                height: 60px;
                transform: translateY(-50%);
                flex-shrink: 0;
                background: #0000003f;

                border-radius: 730px;
                border: 1px solid #34c8e8;
                @media (max-width: 1200px) {
                    width: 50%;
                    background: #0000006c;
                }
            }

            @media (max-width: 768px) {
                display: none;
            }
        }

        .notice {
            margin-top: 70px;
            margin-bottom: 20px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            z-index: 12;

            @media (max-width: 562px) {
                margin-top: 50px;
            }

            p {
                border-radius: 18px;
                border: 1px solid #252d40;
                background: #131b30;
                box-shadow: 0px 4px 4px 0px #00000040;
                padding: 3px 60px;
                color: #e0e0e0;
                font-family: system-ui, "Segoe UI", "Open Sans", "Helvetica Neue", sans-serif;
                white-space: nowrap;

                @media (max-width: 768px) {
                    display: none;
                }
            }
        }

        .hero-txt {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            z-index: 12;

            h1 {
                white-space: pre;
                text-align: center;
                letter-spacing: -1.5px;

                @media (max-width: 768px) {
                    margin-top: 40px;
                }
                span {
                    display: inline-block;
                    position: relative;
                    opacity: 0;
                    transform: translateY(-100%);
                    animation: bounce-in 0.25s cubic-bezier(0.49, 0.37, 0.53, 1.39) forwards;

                    font-size: 76px;
                    font-weight: 900;
                    font-family:
                        system-ui,
                        -apple-system,
                        BlinkMacSystemFont,
                        "Segoe UI",
                        Roboto,
                        Oxygen,
                        Ubuntu,
                        Cantarell,
                        "Open Sans",
                        "Helvetica Neue",
                        sans-serif;
                    // background: linear-gradient(270deg, #83c4eb 0%, #fff 100%);
                    background: linear-gradient(270deg, #88b3e4 0%, #deedf7 100%);
                    background-clip: text;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;

                    &:nth-child(23n)::after {
                        content: "\A";
                        white-space: pre;
                        color: red;
                    }

                    @media (max-width: 992px) {
                        font-size: 54px;
                        padding-right: 0%;
                        max-width: 95%;
                    }
                    @media (max-width: 768px) {
                        font-size: 44px;
                        max-width: 100%;
                    }
                    @media (max-width: 562px) {
                        font-size: 36px;
                    }
                    @media (max-width: 392px) {
                        font-size: 24px;
                    }
                    @media (max-width: 262px) {
                        font-size: 18px;
                    }
                }
            }

            @keyframes bounce-in {
                to {
                    opacity: 1;
                    transform: translateY(0%);
                }
            }

            p {
                width: 60%;
                font-size: 16px;
                text-align: center;
                color: #8cd9f8 !important;
                margin-top: 20px;
                margin-bottom: 10%;
                line-height: 130%;
                z-index: 12;

                @media (max-width: 768px) {
                    margin-bottom: 15%;
                    width: 88%;
                    font-size: small;
                }
                @media (max-width: 392px) {
                    font-size: x-small;
                }
            }
        }

        .curve-lines {
            width: 100%;
            height: 100px;
            padding: 0px 5%;
            left: 0;
            position: absolute;
            display: flex;
            justify-content: space-between;
            transform: translateY(-200%);

            @media (max-width: 768px) {
                padding: 0% 0%;
                transform: translateY(-90%);
                svg {
                    height: 160px;
                    width: 30%;
                }
            }
            @media (max-width: 562px) {
                padding: 0% 0%;
                transform: translateY(-90%);
                svg {
                    height: 150px;
                    width: 30%;
                }
            }
            @media (max-width: 392px) {
                padding: 70px 0 0 0;
                svg {
                    height: 120px;
                    width: 30%;
                }
            }

            .svg-container {
                display: flex;
                justify-content: space-between;
                width: 100%;
            }

            // .growing-path {
            //     animation: grow 2s linear infinite;
            // }

            // @keyframes grow {
            //     0% {
            //         stroke-dasharray:  100% 0;
            //     }
            //     100% {
            //         stroke-dasharray: 100% 100%;
            //     }
            // }

            @keyframes grow {
                to {
                    stroke-dashoffset: 0;
                }
            }
        }

        .scroll-txt {
            text-align: center;
            margin-bottom: 20px;
        }
        .card-with-two-gradient {
            width: 90%;
            height: 400px;
            position: absolute;
            display: flex;
            overflow: visible;
            left: 5%;
            z-index: -1;
            border-radius: 23px;
            background:
                linear-gradient(to bottom right, #000 0%, #e0e1ea 0%, rgba(55, 57, 98, 0.18) 0%, #090a1c 50%) bottom
                    right / 50% 50% no-repeat,
                linear-gradient(to bottom left, #000 0%, #e0e1ea 0%, rgba(55, 57, 98, 0.18) 0%, #090a1c 50%) bottom left /
                    50% 50% no-repeat,
                linear-gradient(to top left, #000 0%, #e0e1ea 0%, rgba(55, 57, 98, 0.18) 0%, #090a1c 50%) top left / 50%
                    50% no-repeat,
                linear-gradient(to top right, #000 0%, #e0e1ea 0%, rgba(55, 57, 98, 0.18) 0%, #090a1c 50%) top right /
                    50% 50% no-repeat;
            box-shadow:
                0px 4px 4px 0px rgba(0, 0, 0, 0.25),
                0px 4px 4px 0px rgba(0, 0, 0, 0.25);

            @media (max-width: 768px) {
                transform: translateY(-8%);
            }

            .green-gradient {
                width: 50%;
                height: 50%;
                border-radius: 972px;
                background: radial-gradient(50% 50% at 50% 50%, #42c0e8 0%, rgba(26, 187, 238, 0) 100%);
            }
            .red-gradient {
                width: 50%;
                height: 50%;
                border-radius: 972px;
                background: radial-gradient(50% 50% at 50% 50%, rgba(220, 49, 96, 0.82) 0%, rgba(254, 47, 103, 0) 100%);
            }
        }
        .project-cover-promo-card {
            background: #794444;
            // position: absolute;
            // left: 0;
            // width: 100%;
            height: auto;
            margin-top: 80px;
            // border-radius: 20px;
            // border: 1px solid #3894fcea;

            background: #191b25;
            // background-image: url("./../../assets/projectsbg.png");

            // box-shadow: 5px 5px 30px 0px rgba(0, 0, 0, 0.25);
            // backdrop-filter: blur(2px);

            @media (max-width: 768px) {
                transform: translateY(-8%);
            }
            video {
                margin: auto;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 20px;
                width: 100%;
                height: 100%;
            }
        }
    }
}
.quote {
    font-family: helvetica;
    font-weight: 300;
    font-size: 15vw;
    text-transform: uppercase;
}

// ////////////////
.carousel {
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: space-around;
}
.carousel__list {
    display: flex;
    list-style: none;
    position: relative;
    justify-content: center;
}
.carousel__item {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 65vw;
    max-width: 1000px;
    aspect-ratio: 1.77/1;
    border-radius: 20px;
    position: absolute;
    background-color: var(--eerie-black);
    border: hsla(0deg 0% 100% / 0.2) solid 1px;
    transition: all 0.25s ease-in-out;
    box-shadow: 0 0 20px 0px hsla(0deg 0% 0% / 0.4);
}
.carousel__item:hover {
    border: hsla(0deg 0% 100% / 1) solid 1px;
    box-shadow: 0 0 45px 0px hsla(0deg 0% 60% / 0.8);
    transition: all 0.25s ease-in-out;
}

@media screen and (max-width: 1000px) {
    .carousel__item {
        width: calc(100vw - 10rem);
    }
}
@media screen and (max-width: 768px) {
    .carousel__item {
        width: calc(100vw - 2rem);
        padding-left: 1rem;
        padding-right: 1rem;
    }
}
.carousel__item:nth-child(1) {
    background-image: url(https://images.pexels.com/photos/5400806/pexels-photo-5400806.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1);
    background-position: center;
    background-size: cover;
}
.carousel__item:nth-child(2) {
    background-image: url(https://images.pexels.com/photos/1302325/pexels-photo-1302325.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1);
    background-position: center;
    background-size: cover;
}
.carousel__item:nth-child(3) {
    background-image: url(https://images.pexels.com/photos/1038935/pexels-photo-1038935.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1);
    background-position: center;
    background-size: cover;
}
.carousel__item:nth-child(4) {
    background-image: url(https://images.pexels.com/photos/3043424/pexels-photo-3043424.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1);
    background-position: center;
    background-size: cover;
}
.carousel__item:nth-child(5) {
    background-image: url(https://images.pexels.com/photos/125509/pexels-photo-125509.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1);
    background-position: center;
    background-size: cover;
}
.carousel__item[data-pos="0"] {
    z-index: 5;
    border: solid 1px hsla(0deg 0% 90% / 0.7);
    box-shadow: 0 0 60px 0px hsla(0deg 0% 100% / 0.25);
}

.carousel__item[data-pos="-1"],
.carousel__item[data-pos="1"] {
    filter: brightness(50%);
}
.carousel__item[data-pos="-1"] {
    transform: translateX(-15%) scale(0.9);
    z-index: 4;
}
.carousel__item[data-pos="1"] {
    transform: translateX(15%) scale(0.9);
    z-index: 4;
}
.carousel__item[data-pos="-2"],
.carousel__item[data-pos="2"] {
    filter: brightness(20%);
}
.carousel__item[data-pos="-2"] {
    transform: translateX(-25%) scale(0.8);
    z-index: 3;
}
.carousel__item[data-pos="2"] {
    transform: translateX(25%) scale(0.8);
    z-index: 3;
}

#particles-js {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: -2;
}
