.skill-page {
	width: 100%;
	height: auto;
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	padding: 8vh 5px;

	.container {
		height: auto;
		p {
			display: flex;
			width: 175px;
			height: 25px;
			flex-direction: column;
			justify-content: center;

			font-family: Inter;
			font-size: 24px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			letter-spacing: 3.3px;

			flex-shrink: 0;
			background: linear-gradient(180deg, #34c8e8 0%, #34c8e800 100%);
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;

			@media(max-width: 768px){
				font-size: 14px;
			}
		}

		h1 {
			font-family: Inter;
			font-size: 112px;
			// line-height: 112px;
			font-style: normal;
			font-weight: 700;
			// letter-spacing: -12.54px;
			background: linear-gradient(180deg, #34c8e8 0%, rgba(52, 200, 232, 0) 100%);
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;

			@media(max-width: 768px){
				font-size: 54px;
			}
			@media(max-width: 392px){
				font-size: 34px;
			}
		}
	}
	hr {
		width: 100%;
		height: 1px;
		border: none;
		opacity: .5;
		background: linear-gradient(180deg, rgba(52, 200, 232, 0.5) 41.15%, rgba(52, 200, 232, 0) 100%);
	}
}
