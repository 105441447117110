footer {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 20px 0px;
    border-top: 1px solid $teal;
    background: linear-gradient(180deg, #0000007d 100%, #ffffff1a 200%);
    box-shadow: 0px 4px 24px 0px #23105e40;
    backdrop-filter: blur(22.5px);



    .container {
        display: flex;
        justify-content: space-between;
        padding-top: 50px;
        gap: 5%;
        position: relative;

        @media (max-width: 768px) {
            display: block;
        }

        .foot-left {
            width: 100%;
            margin-bottom: 40px;
            .footer-socials-link {
                h4 {
                    color: $grey;
                    margin-bottom: 50px;
                }
                .footer-socials-link-txt {
                    display: flex;
                    justify-content: space-between;
                    font-size: xx-large;
                    font-weight: 600;

                    li {
                        margin-bottom: 10px;
                        padding-left: 30px;
                        font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande", "Lucida Sans Unicode",
                            Geneva, Verdana, sans-serif;
                            @media (max-width: 768px) {
                                font-size: large;
                                font-weight: 500;
                            }
                            @media (max-width: 562px) {
                                font-size: medium;
                                font-weight: 400;
                            }
                    }
                    li:before {
                        content: "";
                        margin-left: -25px;
                        margin-top: 5px;
                        position: absolute;
                        border-top: 15px solid $teal;
                        border-left: 15px solid transparent;
                        @media (min-width: 768px) {
                            transform: translateY(5px);
                        }
                    }
                }
                .sla-dis-team {
                    width: 100%;
                }
                .git-link-Drib {
                    width: 100%;
                }
            }
        }

        .foot-right {
            width: 70%;
            margin-bottom: 80px;
            padding-top: 70px;

            .foot-pho,
            .foot-em {
                display: flex;
                gap: 12px;
                margin-bottom: 25px;
                font-size: large;

                .pho-ico,
                .mail-ico {
                    @extend .place-items-center;
                    background: #777777;
                    border-radius: 50px;
                    width: 40px;
                    height: 40px;
                }
                small {
                    color: $grey;
                }
            }
        }

        .footer-socials-icons {
            position: absolute;
            margin-left: -15px;
        }
        .move-top {
            z-index: 199;
            height: 20px;
            position: absolute;
            right: 40px;
            top: 150px;
            white-space: nowrap;
            rotate: -90deg;
            a {
                background: none;
                border: none;
                font-size: large;
                color: $grey;
                cursor: pointer;
                span {
                    color: $teal;
                }
            }
        }

        @media (max-width: 768px) {
            .footer-socials-icons {
                display: none;
            }
            .foot-right {
                width: 100%;
            }
            .move-top {
                display: none;
            }
            .footer-socials-link-txt {
                font-size: x-large !important;
                font-weight: 400 !important;
            }
            .foot-right {
                padding-top: 20px;
            }
        }
    }
}

// footer bottom wave style
.footer-wave {
    overflow-y: hidden;
    bottom: 0px;
    width: 100%;
    height: 30px;
    background: linear-gradient(180deg, #0000007d 100%, #ffffff1a 200%);
    backdrop-filter: blur(22.5px);

    img {
        width: 100%;
        height: 30px;
    }
}
